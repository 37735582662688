<template>
  <div>
    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-5">
        <v-row>
          <v-col
            cols="12"
            sm="3"
            lg="2"
          >
            <!-- Create Payment Method -->
            <v-btn
              color="primary"
              class="me-3"
              @click="isSaveDialogOpen = true"
            >
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiPlus }}
              </v-icon>
              <span>Add Payment Methods</span>
            </v-btn>
          </v-col>

          <!-- Save Dialog -->
          <v-dialog
            v-model="isSaveDialogOpen"
            width="500"
            persistent
          >
            <v-card>
              <v-card-title class="d-flex align-center mv-4 mb-4">
                {{ paymentMethod.id ? 'Edit' : 'New' }} Payment Method
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  small
                  @click="isSaveDialogOpen = false; saveForm.reset(); paymentMethod = {}"
                >
                  <v-icon size="22">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text>
                <v-form
                  ref="saveForm"
                  v-model="saveValid"
                >
                  <v-text-field
                    v-model="paymentMethod.name"
                    outlined
                    dense
                    label="Name"
                    :rules="[validators.required]"
                    hide-details="auto"
                    class="mb-6"
                  ></v-text-field>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="primary"
                  block
                  :loading="savePaymentMethodLoading"
                  :disabled="savePaymentMethodLoading || !saveValid"
                  @click="savePaymentMethod"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-spacer></v-spacer>

          <v-col
            cols="12"
            sm="4"
            lg="3"
            class="d-flex pl-sm-0"
          >
            <!-- Search Payment Method -->
            <v-tooltip
              :left="$vuetify.breakpoint.smAndUp"
              :top="$vuetify.breakpoint.xsOnly"
              :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
              :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
              :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
              max-width="250"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  size="30"
                  class="mr-3 my-auto hover-pointer d-none d-sm-block"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="25">
                    {{ icons.mdiAlertCircleOutline }}
                  </v-icon>
                </v-avatar>
              </template>
              <h3 class="my-2">
                How to use the search bar
              </h3>
              <p class="mb-1">
                You can search using:
              </p>
              <ul class="mb-2">
                <li>
                  Payment Method
                </li>
              </ul>
            </v-tooltip>
            <v-text-field
              v-model="searchText"
              :append-icon="icons.mdiMagnify"
              single-line
              dense
              outlined
              hide-details
              placeholder="Search Payment Methods"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="4"
            sm="2"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                @input="listLengthChange"
              ></v-select>
              <div class="pl-3">
                Rows
              </div>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            sm="10"
            cols="4"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchPaymentMethods"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Payment Method List -->
      <v-data-table
        :headers="tableHeaders"
        :items="paymentMethods"
        :options.sync="options"
        :items-per-page="100"
        :loading="listLoading"
        mobile-breakpoint="0"
        hide-default-footer
        fixed-header
        class="text-no-wrap"
      >
        <!-- Actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="me-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="fetchPaymentMethod(item.id); isSaveDialogOpen = true">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="paymentMethod = item; isDeleteDialogOpen = true">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>

      <!-- Delete Dialog -->
      <v-dialog
        v-model="isDeleteDialogOpen"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="d-flex align-center mv-4">
            Delete {{ paymentMethod.name }}?
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isDeleteDialogOpen = false; paymentMethod = {}"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            This payment method will be removed from this list. It will no longer appear in searches or available for use.
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="error"
              block
              :loading="deletePaymentMethodLoading"
              :disabled="deletePaymentMethodLoading"
              class="mt-3"
              @click="deletePaymentMethod(paymentMethod.id)"
            >
              Yes, remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="4"
            sm="2"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                @input="listLengthChange"
              ></v-select>
              <div class="pl-3">
                Rows
              </div>
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            sm="10"
            cols="4"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="fetchPaymentMethods"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiDotsVertical,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCalendar,
  mdiPrinter,
  mdiMagnify,
  mdiClose,
} from '@mdi/js'
import {
  ref,
  inject,
  computed,
  watch,
} from '@vue/composition-api'
import { required } from '@core/utils/validation'

export default {
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')

    // Properties
    const paymentMethods = ref([])
    const paymentMethod = ref({})
    const searchText = ref('')

    const saveForm = ref(null)
    const saveValid = ref(false)
    const isSaveDialogOpen = ref(false)
    const savePaymentMethodLoading = ref(false)

    const isDeleteDialogOpen = ref(false)
    const deletePaymentMethodLoading = ref(false)

    // Table Handlers
    const listLoading = ref(false)
    const options = ref({
      sortBy: ['name'],
      sortDesc: [false],
    })
    const listLengthOptions = ref([20, 50, 100])
    const tablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const tableHeaders = computed(() => (
      [
        { text: 'NAME', value: 'name' },
        {
          text: 'ACTIONS',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ]
    ))

    // Methods
    const fetchPaymentMethods = () => {
      listLoading.value = true
      store
        .dispatch('knowledgeBaseStore/fetchPaymentMethods', {
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
          sort_by: options.value.sortBy[0],
          sort_dir: options.value.sortDesc[0] ? 'desc' : 'asc',
          search_text: searchText.value,
        })
        .then(response => {
          const { data, pagination } = response.data
          paymentMethods.value = data
          tablePagination.value = pagination
          listLoading.value = false
        })
        .catch(error => {
          listLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching payment methods. Please refresh!')
        })
    }
    const fetchPaymentMethod = id => {
      store
        .dispatch('knowledgeBaseStore/fetchPaymentMethod', { id })
        .then(response => {
          paymentMethod.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching payment method. Please refresh!')
        })
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      fetchPaymentMethods()
    }
    const savePaymentMethod = () => {
      savePaymentMethodLoading.value = true
      store
        .dispatch(`knowledgeBaseStore/${paymentMethod.value.id ? 'updatePaymentMethod' : 'createPaymentMethod'}`, paymentMethod.value)
        .then(response => {
          snackbarService.success(response.data.message)
          fetchPaymentMethods()
          isSaveDialogOpen.value = false
          savePaymentMethodLoading.value = false
          saveForm.value.reset()
          paymentMethod.value = {}
        })
        .catch(error => {
          savePaymentMethodLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while adding payment method. Please refresh!')
        })
    }
    const deletePaymentMethod = paymentMethodId => {
      deletePaymentMethodLoading.value = true
      store
        .dispatch('knowledgeBaseStore/deletePaymentMethod', { id: paymentMethodId })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchPaymentMethods()
          isDeleteDialogOpen.value = false
          deletePaymentMethodLoading.value = false
          paymentMethod.value = {}
        })
        .catch(error => {
          deletePaymentMethodLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while deleting payment method. Please refresh!')
        })
    }

    // Watch
    let timer = null
    watch([searchText, options], () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      timer = setTimeout(() => {
        fetchPaymentMethods()
      }, 200)
    })

    return {
      // Properties
      paymentMethods,
      paymentMethod,
      searchText,

      saveForm,
      saveValid,
      isSaveDialogOpen,
      savePaymentMethodLoading,

      isDeleteDialogOpen,
      deletePaymentMethodLoading,

      // Table Handlers
      listLoading,
      options,
      listLengthOptions,
      tablePagination,
      tableHeaders,

      // Validators
      validators: {
        required,
      },

      // Icons
      icons: {
        mdiPlus,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPrinter,
        mdiMagnify,
        mdiClose,
      },

      // Methods
      fetchPaymentMethods,
      fetchPaymentMethod,
      listLengthChange,
      savePaymentMethod,
      deletePaymentMethod,
    }
  },
}
</script>
